<template>
  <b-card>
    <validation-observer ref="passwordDefinition" tag="form">
      <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group :label="$t('profile.old_password')" label-for="account-old-password">
              <validation-provider #default="{ errors }" :name="$t('profile.old_password')" rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-old-password" v-model="passwordValueOld" name="old-password" :type="passwordFieldTypeOld" :placeholder="$t('profile.old_password_placeholder')" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" :label="$t('profile.new_password')">
              <validation-provider #default="{ errors }" :name="$t('profile.new_password')" rules="required|min:6|max:18" :v-id="$t('profile.new_password')">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew" name="new-password" ref="newPassword" :placeholder="$t('profile.new_password_placeholder')" />

                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group label-for="account-retype-new-password" :label="$t('profile.new_password_retype')">
              <validation-provider #default="{ errors }" :name="$t('profile.new_password_retype')" :rules="'required|confirmed:' + $t('profile.new_password')">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-retype-new-password" v-model="RetypePassword" :type="passwordFieldTypeRetype" name="retype-password" :placeholder="$t('profile.new_password_retype_placeholder')" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    reset() {
      this.passwordValueOld = "";
      this.newPasswordValue = "";
      this.RetypePassword = "";
    },
    validatePassword() {
      return new Promise((resolve) => {
        this.$refs.passwordDefinition.validate().then((success) => {
          if (success) {
            resolve();
          }
        });
      });
    },
    getData() {
      const response = {
        old_password: this.passwordValueOld,
        new_password: this.newPasswordValue,
      };

      return response;
    },
  },
};
</script>
