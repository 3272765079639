<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-overlay
      :show="!ready"
      variant="dark"
      opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <!-- general tab -->
      <b-tab active @click="type = 1">
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("Account") }}</span>
        </template>
        <validation-observer ref="accountObserver">
          <user-account
            v-if="ready"
            ref="account"
            :user-data="user"
            :is-profile="true"
          />
        </validation-observer>
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab @click="type = 2">
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("Password") }}</span>
        </template>

        <account-setting-password ref="password" />
      </b-tab>
      <!--/ change password tab -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="save()"
        >
          {{ $t("profile.save") }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="reset()"
        >
          {{ $t("profile.reset") }}
        </b-button>
      </div>
    </b-overlay>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BButton, BOverlay } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import UserAccount from "../User/components/UserAccount.vue";
import AccountSettingPassword from "./components/AccountSettingPassword.vue";
import {
  CHANGE_PASSWORD,
  GET_ACCOUNT,
  UPDATE_ACCOUNT,
} from "@/store/services/user-service";
import { SET_NOTIFICATION } from "@/store/services/module-service";

export default {
  name: "Profile",
  components: {
    BTabs,
    BTab,
    BButton,
    BOverlay,
    ValidationObserver,
    UserAccount,
    AccountSettingPassword,
  },
  data() {
    return {
      ready: false,
      type: 1, // 1 => Hesap 2 => Şifre
      user: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ACCOUNT).then((response) => {
          if (response.status) {
            this.user = response.data;
            this.ready = true;
            resolve();
          } else {
            this.ready = false;
            reject();
          }
        });
      });
    },
    save() {
      if (this.type === 1) {
        this.validateAccount().then((account) => {
          this.$store.dispatch(UPDATE_ACCOUNT, account).then((response) => {
            if (response.status) {
              this.$router.push({ name: "home" });
            }
          });
        });
      } else {
        this.validatePassword().then((passwords) => {
          this.$store.dispatch(CHANGE_PASSWORD, passwords).then((response) => {
            if (response.status) {
              this.$router.push({ name: "home" });
            }
          });
        });
      }
    },
    reset() {
      this.ready = false;
      this.getData().then(() => {
        Promise.all([
          this.$refs.account.reset(),
          this.$refs.password.reset(),
        ]).then(() => {
          this.$store.commit(SET_NOTIFICATION, {
            title: this.$t("ResetEvent"),
            status: true,
            message: this.$t("Reset"),
          });
        });
      });
    },
    validateAccount() {
      return new Promise((resolve) => {
        this.$refs.accountObserver.validate().then(() => {
          resolve(this.$refs.account.getData());
        });
      });
    },
    validatePassword() {
      return new Promise((resolve) => {
        this.$refs.password.validatePassword().then(() => {
          resolve(this.$refs.password.getData());
        });
      });
    },
  },
};
</script>
